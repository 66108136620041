export const isOrderId = (id?: string): boolean => {
  // @ts-ignore
  return id?.endsWith(":Orders") || id?.length > 20;
};

import { ProductSpecialType, type Order, type OrderError, type SourceForStoreOptions } from "@/generated/requests/pos";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useEffect } from "react";
import { trackFBPurchase } from "../static/lib/facebook";
import {
  logPurchase,
  firebaseTrackRewardsOnReceipt,
  firebaseTrackRewardsOnRewardsPage,
  firebaseTrackOneTapPurchased,
} from "../static/lib/firebase";
import { track } from "../static/lib/tracking";
import { centsToDecimal, fetchLocal, formatPhone, storeLocal } from "../static/lib/util";

import type { EarnRewardsTypes } from "@/components/v2/organisms/Account/RewardsEarnCrumbs";
import type { PublicStoreInfo } from "@/generated/requests/services";
import type { Address } from "@/components/v2/organisms/AddressSelector/AddressRadioButtonStack";
import type { LngLatLike } from "mapbox-gl";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

export const IMAGESTORAGE = "ImageKeyStorage";

const productsToTrack = () => {
  const products = fetchLocal("order")?.what || [];
  return products?.map(({ product, meta, price }, position) => ({
    id: product?.productId,
    name: meta?.title,
    price: price / 100,
    category: meta?.category,
    variant: meta.lineItems.join(", "),
    quantity: 1,
    position,
  }));
};

export enum CheckoutEventLabel {
  Login = "Login",
  ReviewOrder = "Review Order",
  AddNote = "Add Note",
  PickupDetails = "Pickup Details",
  Tips = "Tips",
  AddPaymentMethod = "Add Payment Method",
}

const trackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  const order = fetchLocal("order");
  track({
    event: "checkout",
    eventCategory: "Checkout",
    eventAction: "Viewed",
    eventLabel,
    ecommerce: {
      checkout: {
        actionField: {
          eventLabel,
          ...moreData,
        },
        products: productsToTrack(),
        currency: order?.totals?.total?.currency || "USD",
        amount: (order?.totals?.total?.amount || 0) / 100,
      },
    },
  });
};

// Custom hook for tracking checkout events. Include this in any component that
// needs to track checkout events and it will fire once after the component
// renders initially. Now we don't have to do complex logic to figure out what
// event to send, yay!!!
const useTrackCheckout = (eventLabel: CheckoutEventLabel, moreData = {}) => {
  useEffect(() => {
    trackCheckout(eventLabel, moreData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const trackPurchase = (actionField) => {
  const products = productsToTrack();
  const customer = fetchLocal("customer");
  try {
    track({
      orderId: actionField?.id,
      event: "ecomm_event",
      eventCategory: "Purchase",
      eventAction: "Completed",
      ecommerce: {
        purchase: {
          actionField,
          products,
          itemCount: products?.length || 0,
        },
      },
      eventLabel: "Purchase",
      eventValue: actionField?.revenue || 0,
      user_data: {
        email: customer?.email || "",
        phone: formatPhone(customer?.phoneNumber || ""),
      },
    });
  } catch (error) {
    console.error("Error tracking purchase:", error);
    // We don't want to throw an error here as it's not critical to the order process
  }
};

const trackPurchaseWrapper = (
  order: Order,
  details?: { newCustomer?: boolean | undefined; isQuickOrder?: boolean | undefined },
) => {
  let flavorIds = [];
  const productIds = [];
  order.items?.forEach((w) => {
    // @ts-ignore
    flavorIds.push(...(w?.meta?.flavorIds || []));
    // @ts-ignore
    productIds.push(w.product.productId);
  });
  flavorIds = flavorIds.filter((f) => !!f);
  const contentIds = flavorIds.length ? flavorIds : productIds;
  const contentType = flavorIds.length ? "product_group" : "product";
  const total = order.totals?.total?.amount || 0;
  const currency = order.totals?.total?.currency || "USD";

  trackFBPurchase(order.orderId, centsToDecimal(total), currency, contentIds, contentType);
  logPurchase(order.orderId, currency, total, order.totals?.taxes?.amount?.amount);
  details?.isQuickOrder && firebaseTrackOneTapPurchased(order.orderId);
  trackPurchase({
    id: order.orderId || "",
    affiliation: "WEB",
    // @ts-ignore
    type: order.type || "",
    revenue: centsToDecimal(total),
    value: centsToDecimal(total),
    tax: centsToDecimal(order.totals?.salesTax?.amount?.amount),
    shipping: centsToDecimal(order.totals?.shippingFee?.amount),
    delivery: centsToDecimal(order.totals?.deliveryFee?.amount),
    new_customer: details?.newCustomer,
    currency,
  });
  trackPurchaseBraze(order);
};

const trackPurchaseBraze = (order: Order) => {
  const optionIds: string[] = [];
  order?.items?.forEach((item) => {
    item?.modifiers?.forEach((modifier) => {
      modifier?.options?.forEach((option) => {
        if (option?.modifierOptionId) {
          optionIds.push(option?.modifierOptionId);
        }
      });
    });
  });

  track({
    event: "order_completed",
    eventLabel: "Order Completed",
    optionIds: [...new Set(optionIds)],
    productIds: [...new Set(order?.items?.map((item) => item?.product?.productId))],
    receiptId: order?.formattedReceipt?.receiptId,
    orderId: order.orderId,
    amount: centsToDecimal(order.totals?.total?.amount),
  });
};

export const trackGiftcard = (giftCardObj) => {
  const cart = giftCardObj?.apiOrderData;
  const orderId = cart?.orderId;
  const total = cart?.totals?.total?.amount || 0;
  const tax = cart?.totals?.salesTax?.amount?.amount || 0;
  const currency = cart.totals?.total?.currency || "USD";

  trackFBPurchase(orderId, centsToDecimal(total), currency, [giftCardObj?.product?.product?.productId], "product");
  logPurchase(orderId, currency, total, tax);
  trackPurchase({
    id: orderId,
    affiliation: "WEB",
    type: "Giftcard",
    revenue: total / 100,
    tax: tax / 100,
    shipping: 0,
    delivery: 0,
  });

  storeLocal(IMAGESTORAGE, null);
};

const poll = async (checkSuccess, complete, timedOut, interval, maxAttempts = 5) => {
  if (maxAttempts == 0) {
    if (timedOut) {
      timedOut();
    }
    return;
  }
  if (await checkSuccess()) {
    complete();
    return;
  }

  setTimeout(() => {
    poll(checkSuccess, complete, timedOut, interval, maxAttempts - 1);
  }, interval);
};

export const resizeImageIfNeeded = (url = "", size = 600) => {
  if (!url) {
    return "";
  }

  if (url.includes("generated")) {
    return url;
  }

  return `https://crumbl.video/cdn-cgi/image/width=${size}/${normalizeSrc(url)}`;
};

const normalizeSrc = (src) => {
  return src?.startsWith("/") ? src.slice(1) : src;
};

export { poll, productsToTrack, trackCheckout, trackPurchase, trackPurchaseWrapper, useTrackCheckout };

export type UpsertOrderResponse = {
  errors?: OrderError[];
  order?: any;
  paymentIntent?: {
    paymentIntentId: string;
    clientSecret: string;
    amount: number;
  };
};

export const getOptionsForCatering = (options: SourceForStoreOptions) => {
  options = {
    ...options,
    productFilters: {
      ...options?.productFilters,
      includeAny: [ProductSpecialType.LargeOrder],
    },
  };
  return { options };
};

export function trackRewardsClickedOnReceipt(type: EarnRewardsTypes) {
  firebaseTrackRewardsOnReceipt(type);
}
export function trackRewardsClickedOnRewardsPage(type: EarnRewardsTypes) {
  firebaseTrackRewardsOnRewardsPage(type);
}

/**
 * Interface for objects with geographic coordinates
 */
interface GeoPoint {
  latitude: number;
  longitude: number;
  [key: string]: any; // Allow for additional properties
}

/**
 * Calculates the distance between two geographic points using the Haversine formula
 * @param lat1 - Latitude of the first point in degrees
 * @param lon1 - Longitude of the first point in degrees
 * @param lat2 - Latitude of the second point in degrees
 * @param lon2 - Longitude of the second point in degrees
 * @returns Distance in kilometers
 */
function calculateDistance(lat1: string, lon1: string, lat2: string, lon2: string): number {
  // Earth's radius in kilometers
  const R = 6371;

  // Convert degrees to radians
  const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

  const dLat = toRadians(Number(lat2) - Number(lat1));
  const dLon = toRadians(Number(lon2) - Number(lon1));

  // Haversine formula
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(Number(lat1))) * Math.cos(toRadians(Number(lat2))) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
}

/**
 * Sorts an array of objects with latitude and longitude by proximity to a reference point
 * @param storeList - Array of objects with latitude and longitude properties
 * @param referencePoint - The reference point to measure distance from
 * @returns A new sorted array ordered by proximity (closest first)
 */
export function sortByProximity(storeList: PublicStoreInfo[], userLat, userLng): PublicStoreInfo[] {
  // Create a copy of the input array to avoid modifying the original
  return [...storeList].sort((a, b) => {
    const distanceA = calculateDistance(a.latitude, a.longitude, userLat, userLng);

    const distanceB = calculateDistance(b.latitude, b.longitude, userLat, userLng);

    return distanceA - distanceB;
  });
}

function haversine(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371.0;

  const radLat1 = (Math.PI / 180) * lat1;
  const radLon1 = (Math.PI / 180) * lon1;
  const radLat2 = (Math.PI / 180) * lat2;
  const radLon2 = (Math.PI / 180) * lon2;

  const dlat = radLat2 - radLat1;
  const dlon = radLon2 - radLon1;

  const a = Math.sin(dlat / 2) ** 2 + Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
}
export function findClosestAddress(addresses: Address[], currentLocation: LngLatLike) {
  if (!currentLocation) return null;
  let closestAddress = null;
  let minDistance = Number.POSITIVE_INFINITY;
  const { lat: currentLat, lng: currentLng } =
    typeof currentLocation === "object" && "lng" in currentLocation && "lat" in currentLocation
      ? currentLocation
      : { lat: 0, lng: 0 };
  for (const address of addresses) {
    const distance = haversine(currentLat, currentLng, address.latitude, address.longitude);
    if (distance < minDistance) {
      minDistance = distance;
      closestAddress = address;
    }
  }

  return closestAddress;
}

export const capitalizeFirstLetter = (str: string | undefined) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
